<template>
    <div>
        <DataTable :value='events' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='event_type'
                   :rowHover='true'
                   :loading='loading'
                   responsiveLayout='scroll'
        >
            <template #empty>
                Non ci sono subscriptions da mostrare.
            </template>
            <template #loading>
                Loading ... Please wait.
            </template>
            <Column field='event_type' header='Evento' :sortable='true' style='min-width:1rem'></Column>
            <Column field='num' header='N' :sortable='true' style='min-width:1rem'></Column>
            <Column field='created_at' header='Created' :sortable='true' style='min-width:1rem'>
                <template #body='slotProps'>{{ts2date(slotProps.data.created_at)}}</template>
            </Column>
            <Column field='updated_at' header='Updated' :sortable='true' style='min-width:1rem'>
                <template #body='slotProps'>{{ts2date(slotProps.data.updated_at)}}</template>
            </Column>
            <Column field='utm_src' header='UTM' style='min-width:1rem'>
                <template #body='slotProps'>
                    <Badge v-if='slotProps["data"]["utm_campaign"]' :title='slotProps["data"]["utm_campaign"]' severity='success'>C</Badge>
                    <Badge v-if='slotProps["data"]["utm_medium"]' :title='slotProps["data"]["utm_medium"]' severity='warning'>M</Badge>
                    <Badge v-if='slotProps["data"]["utm_src"]' :title='slotProps["data"]["utm_src"]' severity='info'>S</Badge>
                    <Badge v-if='slotProps["data"]["utm_content"]' :title='slotProps["data"]["utm_content"]' severity='info'>N</Badge>
                    <Badge v-if='slotProps["data"]["utm_term"]' :title='slotProps["data"]["utm_term"]' severity='info'>T</Badge>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/Users');
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';

export default {
    mixins: [Navigations, Notifications],
    mounted() {
        this.downloadEvents();
    },
    methods: {
        ...mapActions(['getEvents']),
        downloadEvents() {
            this.getEvents(this.id).catch(err => {
                    this.ShowStandardError(err.response);
                },
            );
        },
    },
    computed: {
        ...mapGetters(['loading', 'events']),
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
};
</script>